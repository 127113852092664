@import url('https://fonts.googleapis.com/css2?family=Open+Sans&family=Roboto&display=swap');

body {
    font-family: 'Open Sans', sans-serif;
    color: #212322;
    background: #86C8BC;
    overflow: hidden;
    font-size: 16px;
    padding: 0;
    margin: 0;
}

.logo-holder {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    text-align: center;
    width: 100vw;
    position: relative;
}
.corso-logo {
    position: relative;
    filter: drop-shadow(-8px -12px 0px #212322);
}
.corso-logo:before {
    position: absolute;
    width: 550px;
    height: 320px;
    content: "";
    background: #EAA794;
    z-index: -1;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    clip-path: polygon(50% 0, 50% 0, 100% 25%, 100% 75%, 50% 100%, 50% 100%, 0% 75%, 0% 25%);
}
.corso-logo:after {
    position: absolute;
    width: 530px;
    height: 300px;
    content: "";
    background: #fff;
    z-index: -1;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    clip-path: polygon(50% 0, 50% 0, 100% 25%, 100% 75%, 50% 100%, 50% 100%, 0% 75%, 0% 25%);
}
.corso-logo img {
    display: block;
    width: 80%;
    margin: 0 auto 40px;
}

.footer {
    position: fixed;
    bottom: 0;
    background: #eee4d8;
    text-align: center;
    width: 100%;
    padding: 10px 0;
    font-size: 15px;
}
.footer a {
    color: #212322;
}